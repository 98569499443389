'use strict';

let appointment = () => {

    let form = `
        <form class="appointment-form" action="/appointment/send-message" method="post">
            <div class="appointment-title">
                записаться на приём
            </div>
            <div class="appointment-block">
                <input type="text" name="question[surname]" placeholder="Фамилия *">
                <input type="text" name="question[name]" placeholder="Имя *">
                <input type="text" name="question[father_name]" placeholder="Отчество">
                <input type="text" name="question[phone]" placeholder="Телефон *">
                <input type="text" name="question[email]" placeholder="Адрес эл. почты *">
                <input type="date" name="question[appointment_date]" placeholder="Дата визита: ДД.ММ.ГГГГ *">
                <select id="address" name="question[department]">
                    <option value="г. Архангельск, ул. Штурманская, д.1">г. Архангельск, ул. Штурманская, д.1</option>
                    <option value="г.Новодвинск, ул. Фронтовых бригад , д.13">г.Новодвинск, ул. Фронтовых бригад , д.13</option>
                    <option value="г. Новодвинск, ул. Советов, д.19">г. Новодвинск, ул. Советов, д.19</option>
                    <option value="г. Новодвинск, ул. 50 лет Октября, д.32">г. Новодвинск, ул. 50 лет Октября, д.32</option>
                    <option value="г. Новодвинск, ул. 3-й Пятилетки, д.23">г. Новодвинск, ул. 3-й Пятилетки, д.23</option>
                </select>
                <textarea id="" name="question[message]" cols="30" rows="10" placeholder="Ваше сообщение"></textarea>
                <input class="privacy" id="privacy" name="i_agree" type="checkbox" />
                <label class="privacy-label" for="privacy">
                    <span>Согласие на обработку персональных данных</span><br />
                    <a href="/data/97ab55ed30c22a2690955e1c2d676c71.doc">
                        (Ознакомиться с Политикой информационной безопасности)
                    </a>
                </label>
                <button class="g-button">Отправить</button>
                <div class="appointment-text">* Поля, помеченные звёздочкой обязательны к заполнению</div>
            </div>
        </form>
    `;

    popup.insertData(form);
    popup.open();

}

let headerTabs = new Tabs;
headerTabs.add('.header-tab');
